<template>
  <div>
    <router-view/>
  </div>
</template>

<style>

</style>

<script>


export default {
  name: "Layout",
}
</script>
